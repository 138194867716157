/*
 *   File : customise-fund-allocation.js
 *   Author : https://evoqins.com
 *   Description : Modal component to select funds form selected basket
 *   Integrations : lodash
 *   Version : 1.0.0
*/

// import packages
import { Fragment, memo, useEffect, useState } from "react";
import _ from "lodash";

// import components
import { Icon } from "../Icon";
import { PrimaryButton } from "../Buttons";

// import styles
import style from "../../Styles/Components/fund-allocation.module.scss";
import Color from "../../Styles/color.module.scss";

const CustomFundAllocation = (props) => {

    // 
    const [funds, setFunds] = useState(_.cloneDeep(props.fundData));
    const [selectedCategory, setCategory] = useState(null);

    useEffect(() => {
        var my_modal = document.getElementById("fund-allocation");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();

        });
        // eslint-disable-next-line
    }, []);


    function _handleBasketAllocation(lookup_id, key, selected_index, updated_index) {

        let all_funds = Object.assign({}, funds);

        let selected_category = [...all_funds[key]];
        let unselected_fund = { ...selected_category[selected_index] };
        unselected_fund = Object.assign(selected_category[selected_index], {
            selected: false
        });

        let selected_fund = [];
        let selected_lookup_index = selected_category.findIndex(selected_fund => selected_fund.allocation_lookup_id === lookup_id);
        if (selected_lookup_index !== -1) {
            selected_fund = { ...selected_category[selected_lookup_index] };
            selected_fund = Object.assign(selected_category[selected_lookup_index], {
                selected: true
            });
            selected_category[updated_index] = selected_fund;
        }

        selected_category[selected_index] = unselected_fund;
        let updated_fund_list = Object.assign({ ...all_funds }, { [key]: selected_category })
        setFunds(updated_fund_list);

        setCategory(null);
    }

    const _handleEdit = (key) => {
        setCategory(key);
    }

    const _handleSave = () => {
        const lookup_ids = _extractSelectedIds(funds);
        const basket_type = props.basket === 1 ? "ACTIVE" : "PASSIVE"
        document.getElementById("close-modal").dispatchEvent(new Event("click"));
        props.submitAllocation(basket_type, lookup_ids);
    }

    function _extractSelectedIds(data) {
        const selectedIds = [];

        Object.values(data).forEach(category => {
            category.forEach(item => {
                if (item.selected) {
                    selectedIds.push(item.allocation_lookup_id);
                }
            });
        });

        return selectedIds;
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_fund_modal}`}
                id="fund-allocation"
                tabIndex="-1"
                aria-labelledby="select-career"
                aria-hidden="true"
                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content ${style.e_content} padding-22px-bottom`}>
                        <div className="border-bottom-1px border-silver-sand ps-sm-4 pe-sm-3 px-3">
                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                    Fund Customization
                                </h6>
                                <Icon icon="close-btn"
                                    size={24}
                                    color={Color.steel_blue}
                                    id="close-modal"
                                    className="cursor-pointer"
                                    data-bs-dismiss="modal" />
                            </div>
                        </div>


                        <Fragment>
                            {/*Funds list to edit existing allocation */}
                            <div className="e-font-14 mb-2 ps-sm-4 pe-sm-3 px-3 color-eerie-black e-montserrat-semi-bold e-line-height-24 mt-3 d-flex align-items-center gap-4px">
                                <img src={props.basket === 1 ?
                                    require("../../Assets/Images/Global/basket-tab.svg").default
                                    :
                                    require("../../Assets/Images/Global/Passive-tab.svg").default}
                                    alt="basket icon" />
                                {props.basket === 1 ? "Active " : "Passive "} Fund Customization
                            </div>
                            <p className="color-blue-gray e-font-14 ps-sm-4 pe-sm-3 px-3 e-line-height-20 e-montserrat-regular mb-0">You may choose an alternative fund from the respective category.</p>
                            {/* <div className="d-flex align-items-start gap-8px e-bg-ivory-bridge p-2 pe-4 border-radius-12px mt-2 mb-4 mx-sm-4 mx-3">
                                <span>
                                    <Icon icon="info-circle-yellow"
                                        size={18}
                                        color="#C75802" />
                                </span>
                                <p className="e-montserrat-medium e-font-14 e-line-height-20 color-dark-golden mb-0">
                                    Please be aware that the system will no longer track or monitor this investment, and the  option to rebalance will not be available.
                                </p>
                            </div> */}
                            <div className={`max-h-260px  ${style.e_fund_list}`}>
                                {/* funds */}
                                {
                                    Object.entries(funds).length > 0 ?
                                        Object.entries(funds).map(([key, category_funds], index) => {
                                            let selected_category_funds = [...category_funds];
                                            let selected_index = selected_category_funds.findIndex((fund) => fund.selected === true);
                                            if (selected_index === -1) {
                                                // eslint-disable-next-line
                                                return;
                                            }
                                            let selected_fund = selected_category_funds[selected_index];
                                            let other_funds = selected_category_funds.filter((item) => { return item.selected === false });
                                            return (
                                                <div key={key}
                                                    className={`ps-sm-4 pe-sm-3 ps-3 pe-1 ${selectedCategory === key ?
                                                        'e-bg-whispering-lavender py-3' :
                                                        ''} mt-3`}>

                                                    <div className={`d-flex align-items-center justify-content-between ${other_funds.length > 0 ? "cursor-pointer" : ""}`}
                                                        onClick={() => other_funds.length > 0 && _handleEdit(key)}>
                                                        <div className="d-flex align-items-center gap-4px me-sm-3 me-1">
                                                            <span className="color-eerie-black e-font-14 e-line-height-18 e-montserrat-medium w-14px">{index + 1}.</span>
                                                            <img src={selected_fund.image}
                                                                width={36}
                                                                height={36}
                                                                alt="fund_image"
                                                                draggable={false}
                                                                className="border-1px border-light-gray border-radius-10px me-1 d-sm-block d-none" />
                                                            <img src={selected_fund.image}
                                                                width={28}
                                                                height={28}
                                                                alt="fund_image"
                                                                draggable={false}
                                                                className="border-1px border-light-gray border-radius-6px d-sm-none d-block me-1" />
                                                            <span className="color-eerie-black e-font-14 e-line-height-18 e-montserrat-medium e-xs-font-12">{selected_fund.name}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center ">
                                                            <span className="color-eerie-black e-font-14 e-line-height-24 e-xs-font-12 e-montserrat-semi-bold me-sm-3 me-1">{selected_fund.allocation}%</span>
                                                            {other_funds.length > 0 ? <Icon icon="edit-bg" size={36}
                                                                className='cursor-pointer max-w-30px' />
                                                                :
                                                                <span className="p-sm-3 p-1"></span>}
                                                        </div>
                                                    </div>

                                                    {
                                                        other_funds.length > 0 && selectedCategory === key &&
                                                        <Fragment>
                                                            <div className="ms-sm-4 ms-0 me-1 mt-3 d-flex align-items-center justify-content-between gap-4px">
                                                                <span className="color-eerie-black e-font-14 e-line-height-18 e-montserrat-semi-bold ">Other funds</span>
                                                                {/* <div className="e-bg-green py-2 padding-12px-lr color-white e-font-12 e-line-height-14 e-montserrat-semi-bold border-radius-30px">Recommended</div> */}
                                                            </div>
                                                            {
                                                                other_funds.map((fund, updated_index) => {
                                                                    return (
                                                                        <Fragment key={updated_index}>
                                                                            <div className={`ms-sm-4 ms-0 me-1 padding-14px-tb d-flex align-items-center justify-content-between cursor-pointer ${updated_index === other_funds.length - 1 ? '' : 'border-bottom-1px  border-onyx'}`}
                                                                                onClick={() => _handleBasketAllocation(fund.allocation_lookup_id, key, selected_index, updated_index)}>
                                                                                <div className="d-flex align-items-center gap-8px">
                                                                                    <img src={fund.image} alt={fund.name}
                                                                                        draggable={false}
                                                                                        width={36}
                                                                                        height={36}
                                                                                        className="border-1px border-light-gray border-radius-10px d-sm-block d-none" />
                                                                                    <img src={fund.image}
                                                                                        width={28}
                                                                                        height={28}
                                                                                        alt="fund_image"
                                                                                        draggable={false}
                                                                                        className="border-1px border-light-gray border-radius-6px d-sm-none d-block" />
                                                                                    <span className="e-montserrat-semi-bold e-font-14 e-xs-font-12 e-line-height-20 mb-0 color-eerie-black">
                                                                                        {fund.name}
                                                                                    </span>
                                                                                </div>

                                                                                <Icon icon={'radio-unchecked'}
                                                                                    size={24}
                                                                                    className="max-w-20px" />
                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="d-flex align-items-start padding-12px-all mt-4 e-bg-ivory-bridge border-radius-12px  color-dark-golden e-font-12 e-line-height-20 gap-8px e-montserrat-regular mx-3">
                                            <span>
                                                <Icon icon="info-circle-yellow"
                                                    size={16}
                                                    color="#C75802" />
                                            </span>
                                            Please be aware that the system will no longer track or monitor this investment, and  the option  to rebalance will not be available.
                                        </div>
                                }
                            </div>

                            <div className="row d-flex justify-content-center margin-32px-top px-3">
                                <div className="col-md-6">
                                    <PrimaryButton label={props.label} className="padding-12px-tb w-100"
                                        onPress={_handleSave} />
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </div>
            </div>

        </Fragment >
    )
}
export default memo(CustomFundAllocation)

CustomFundAllocation.defaultProps = {
    basket: 1,
    label: "Save"
}