/*
 *   File : select-step.js
 *   Author : https://evoqins.com
 *   Description : Modal component to set step.
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "../Icon";


// import styles
import style from "../../Styles/Components/select-career.module.scss";
import Slider from "react-slick";


const STEPS_DATA = [
    {
        step_image: require("../../Assets/Images/Dashboard/step-1.png"),
        overlay: require("../../Assets/Images/Dashboard/step-1-overlay.png"),
        title: "Milestone",
        description: "Invest with purpose—whether it’s for a new house, a car, your child’s future, or other important milestones."
    },
    {
        step_image: require("../../Assets/Images/Dashboard/step-2.png"),
        overlay: require("../../Assets/Images/Dashboard/step-2-overlay.png"),
        title: "Curated Portfolio",
        description: "Invest in a strategy aligned with your risk profile, choosing between active or index funds."
    },
    {
        step_image: require("../../Assets/Images/Dashboard/step-3.png"),
        overlay: require("../../Assets/Images/Dashboard/step-3-overlay.png"),
        title: "Import External Investments",
        description: "View, track, and manage all your mutual funds in a single interface."
    },
    {
        step_image: require("../../Assets/Images/Dashboard/step-4.png"),
        overlay: require("../../Assets/Images/Dashboard/step-4-overlay.png"),
        title: "Explore Funds",
        description: "Pick the funds you want and invest like a pro."
    }
]

const SelectStepModal = (props) => {
    return (
        <div className={`modal fade ${style.e_select_career_modal}`}
            id="select-steps"
            tabIndex="-1"
            aria-labelledby="select-steps"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false">

            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content ${style.e_content} ${style.e_step} `}>
                    <div className="pb-5 position-relative">
                        <Icon icon="close"
                            id="close-btn"
                            size={24}
                            className="cursor-pointer position-absolute right-24px top-24px"
                            data-bs-dismiss="modal"
                            onClick={props.closeModal} />
                        <div className="d-flex justify-content-center">
                            <img src={require("../../Assets/Images/Dashboard/Illustration.png")}
                                alt="Select-type"
                                width={122}
                                height={122}
                                draggable={false} />

                        </div>
                        <h3 className="e-montserrat-semi-bold e-font-18 e-line-height-28 color-eerie-black margin-20px-bottom text-center px-4">Make your selection and let's get started!</h3>
                        <Slider slidesToShow={1}
                            slidesToScroll={1}
                            autoplaySpeed={3000}
                            fade={true}
                            autoplay={true}
                            dots={true}
                            arrows={false}
                            // {...settings}
                            dotsClass={"slick-dots e-steps-slider "}>
                            {
                                STEPS_DATA.map((item) => {
                                    return (
                                        <div className="px-4">
                                            <img src={item.step_image}
                                                alt={item.title}
                                                className="w-100 mb-2"
                                                height="auto" />
                                            <div className="position-relative">
                                                <img src={item.overlay}
                                                    alt={item.title}
                                                    className="w-100 mb-2"
                                                    height="auto" />
                                                <div className="position-absolute top-24px left-16px zindex-2">
                                                    <h6 className="color-eerie-black e-font-14 e-montserrat-semi-bold margin-2px-bottom">{item.title}</h6>
                                                    <p className="color-jet-gray e-font-12 line-height-18px e-montserrat-medium">{item.description}</p>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SelectStepModal